import {
  AnalyticService,
  AnalyticsEvent,
  AnalyticsUser,
} from 'modules/Analytics/analytics.interface';

export class ConsoleAnalyticService implements AnalyticService {
  constructor(private readonly enabled: boolean) {}

  init = () => {
    if (this.enabled) console.log('[Analytic]: init');
  };

  trackGqlOperation = (eventName: string, payload?: object) => {
    if (this.enabled)
      console.log('[Analytic]: trackGqlOperation', { eventName, payload });
  };

  trackEvent = <TAnalyticsEventName extends keyof AnalyticsEvent>(
    eventName: TAnalyticsEventName,
    payload: AnalyticsEvent[TAnalyticsEventName]
  ) => {
    if (this.enabled)
      console.log('[Analytic]: trackEvent', { eventName, payload });
  };

  trackPageView = (page: string) => {
    if (this.enabled) console.log('[Analytic]: trackPageView', { page });
  };

  identifyUser = (user: AnalyticsUser) => {
    if (this.enabled) console.log('[Analytic]: identifyUser', { user });
  };

  forgetUser = () => {
    if (this.enabled) console.log('[Analytic]: forgetUser');
  };
}
