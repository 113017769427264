import { ManualOperationNeededError } from '@core/errors';
import * as BrowserSideSentry from '@sentry/browser';
import { SeverityLevel } from '@sentry/browser';
import * as ServerSideSentry from '@sentry/node';
import { Context } from '@sentry/types/types/context';

const wrapWithError = (unknownError: unknown): Error => {
  if (unknownError instanceof Error) return unknownError;

  if (typeof unknownError === 'string') {
    return new Error(unknownError);
  }

  // We are logging unknownError for debugging purposes
  // eslint-disable-next-line no-console
  console.error('unknownError :>> ', unknownError);
  return new Error('An unexpected error has occured');
};

export const reportErrorToSentry = (
  unknownError: unknown,
  { contexts }: { contexts?: { key: string; context: Context }[] } = {}
): void => {
  const error = wrapWithError(unknownError);
  if (process.env.NEXT_PUBLIC_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('handling unknown error: ', { error, contexts });
  }

  if (error instanceof ManualOperationNeededError) {
    contexts?.push({
      key: 'manualOperationGuide',
      context: { notionLink: error.howToFixLink },
    });
  }

  if (typeof window === 'undefined') {
    if (contexts) {
      ServerSideSentry.configureScope((scope) => {
        contexts.forEach(({ key, context }) => {
          scope.setContext(key, context);
        });
      });
    }
    ServerSideSentry.captureException(error);
  } else {
    if (contexts) {
      BrowserSideSentry.configureScope((scope) => {
        contexts.forEach(({ key, context }) => {
          scope.setContext(key, context);
        });
      });
    }
    BrowserSideSentry.captureException(error);
  }
};

export const logMessage = (
  message: string,
  { severity }: { severity?: SeverityLevel } = {}
): void => {
  if (process.env.NEXT_PUBLIC_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('message: ', message);
  }

  if (typeof window === 'undefined') {
    ServerSideSentry.captureMessage(message, severity);
  } else {
    BrowserSideSentry.captureMessage(message, severity);
  }
};

export const logBreadcrumbs = (
  breadcrumb: BrowserSideSentry.Breadcrumb
): void => {
  if (process.env.NEXT_PUBLIC_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('sentry breadcrumbs: ', breadcrumb);
  }
  if (typeof window === 'undefined') {
    ServerSideSentry.addBreadcrumb(breadcrumb);
  } else {
    BrowserSideSentry.addBreadcrumb(breadcrumb);
  }
};
