import {
  DocumentNode,
  LazyQueryResultTuple,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery,
  // eslint-disable-next-line no-restricted-imports
  useQuery,
} from '@apollo/client';
import {
  logBreadcrumbs,
  logMessage,
} from 'modules/Analytics/utils/reportError.utils';
import { useCheckIfUserIsAuthenticated } from 'modules/Authentication/hooks/useCheckIfUserIsAuthenticated.hook';
import { useEffect, useMemo, useState } from 'react';

const useShouldSkipQuery = <TData, TVariables>({
  query,
  queryOptions: { isPublicQuery, skip: initialSkip, ...options },
}: {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  queryOptions: QueryHookOptions<TData, TVariables> & {
    isPublicQuery: boolean;
  };
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { checkIfUserIsAuthenticated } = useCheckIfUserIsAuthenticated();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkIfUserIsAuthenticated({
      onUserAuthenticated: () => {
        setIsAuthenticated(true);
      },
      onUserNotAuthenticated: () => {
        if (!isPublicQuery && !initialSkip) {
          logBreadcrumbs({
            message: 'Non authenticated user tried to query non public query',
            data: { query, queryVariables: options.variables },
          });

          logMessage(
            // @ts-ignore
            `User is not authorized to send this query: ${query.definitions[0].name.value}`
          );
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSkip]); //

  const shouldSkipQuery = useMemo(() => {
    return (!isPublicQuery && !isAuthenticated) || initialSkip;
  }, [isAuthenticated, isPublicQuery, initialSkip]);

  return shouldSkipQuery;
};

/**
 * We chose to override the useQuery hook from Apollo Client
 * because the default options set in the client definition are not taken into account.
 * In particular, the default fetch policy did not work
 * https://github.com/apollographql/apollo-client/issues/9105
 */
export const useApolloQuery = <TData, TVariables>(
  query: TypedDocumentNode<TData, TVariables> | DocumentNode,
  {
    isPublicQuery = false,
    ...options
  }: QueryHookOptions<TData, TVariables> & { isPublicQuery?: boolean } = {}
): QueryResult<TData, TVariables> => {
  const skip = useShouldSkipQuery({
    query,
    queryOptions: { isPublicQuery, ...options },
  });

  const queryResult: QueryResult<TData, TVariables> = useQuery(query, {
    ssr: false,
    fetchPolicy: 'cache-and-network',
    ...options,
    skip,
  });

  // We consider that the query is loading if the query is skipped
  // The main skipped reason is when the router is not ready
  const isLoading = queryResult.loading || !!skip;

  return {
    ...queryResult,
    loading: isLoading,
  };
};

export const useApolloLazyQuery = <TData, TVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): LazyQueryResultTuple<TData, TVariables> => {
  const lazyQueryResult: LazyQueryResultTuple<TData, TVariables> = useLazyQuery(
    query,
    {
      ssr: false,
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

  return lazyQueryResult;
};
