import { Auth0Client, GenericError } from '@auth0/auth0-spa-js';
import { UnspecifiedError } from '@core/errors';
import { AnalyticService } from 'modules/Analytics/analytics.interface';
import {
  AuthService,
  AuthenticatedUser,
  AuthenticationPageConfig,
} from 'modules/Authentication/authentication.interface';

const wrapWithIgnoreLoginRequiredError =
  <T>(asyncFunction: () => Promise<T>) =>
  async (): Promise<T | null> => {
    try {
      const result = await asyncFunction();
      return result;
    } catch (error) {
      if (error instanceof GenericError && error.error === 'login_required') {
        return Promise.resolve(null);
      }
      throw error;
    }
  };

export class AuthenticationAuth0Service implements AuthService {
  constructor(
    private readonly auth0Client: Auth0Client,
    private readonly analyticService: AnalyticService
  ) {
    // This function must be called after the instanciation of the authenticationService
    // It is used to silently log in the user
    // see https://github.com/auth0/auth0-spa-js/blob/8e6cd5d2d184ab54856264564630274180f8e39e/src/Auth0Client.ts#L560
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initializeAuth0Client();
  }

  initializeAuth0Client = async () => {
    // We ignore login_required error as the user is not necessarily logged in
    // see: https://github.com/auth0/auth0-spa-js#configure-the-sdk
    await wrapWithIgnoreLoginRequiredError(this.getTokenSilently)();
  };

  getUser = async (): Promise<AuthenticatedUser | undefined> => {
    const auth0user = await this.auth0Client.getUser();
    if (!auth0user) return;
    return {
      authUserId: auth0user.sub || 'no-id',
      email: auth0user.email,
      email_verified: auth0user.email_verified,
    };
  };

  getAuthorizationHeaders = async () => {
    // We ignore login_required error as the user is not necessarily logged in
    const accessToken = await wrapWithIgnoreLoginRequiredError(
      this.getTokenSilently
    )();
    if (!accessToken) return {};
    return { Authorization: `Bearer ${accessToken}` };
  };

  getTokenSilently = (config?: { ignoreCache: boolean } | undefined) => {
    if (typeof window !== 'undefined') {
      return this.auth0Client.getTokenSilently(config);
    }
    return Promise.resolve(null);
  };

  /**
   * It is possible to pass custom query parameters to the /authorize endpoint when initiating the authentication request.
   * These custom query parameters must have the ext- prefix.
   * see https://auth0.com/docs/customize/universal-login-pages/universal-login-page-templates#custom-query-parameters
   *
   * This adapter prefix all the extraParameters keys with 'ext-'
   * We can then use them in the Auth0 Universal login page template
   * see modules/Authentication/components/Auth0UniversalLoginPageTemplate/auth0-universal-login-page-template.production.html
   */
  private adaptExtraParametersToAuth0Parameters = (
    extraParameters: AuthenticationPageConfig['extraParameters']
  ): Record<string, boolean> => {
    if (!extraParameters) {
      return {};
    }
    return Object.entries(extraParameters).reduce(
      (accumulator, [key, value]) => {
        return { ...accumulator, [`ext-${key}`]: value };
      },
      {}
    );
  };

  goToLoginPage = (config?: AuthenticationPageConfig): Promise<void> =>
    this.auth0Client.loginWithRedirect({
      appState: config?.appState,
      screen_hint: 'login',
      login_hint: config?.email,
      ...this.adaptExtraParametersToAuth0Parameters(config?.extraParameters),
    });

  goToSignupPage = async (config?: AuthenticationPageConfig): Promise<void> => {
    return this.auth0Client.loginWithRedirect({
      appState: config?.appState,
      screen_hint: 'signup',
      login_hint: config?.email,
      ...this.adaptExtraParametersToAuth0Parameters(config?.extraParameters),
    });
  };

  isAuthenticated = () => this.auth0Client.isAuthenticated();

  logout = (config: { returnTo: string }) => {
    this.analyticService.forgetUser();
    return this.auth0Client.logout(config);
  };

  handleRedirectCallback = () => {
    // After login, Auth0 redirects to /redirection-after-login with a queryParam 'code'.
    // Example of url: https://www.app.muzzo.io/redirection-after-login?code=aiGyzvALWv164tv8ASLfid2teuoxQJlurkR9FE4gGlyEc&state=Wm96YUo3T1k4WVV0ZkpuUFVNQm45aTlDYWhvYnRpS0tEUVB5X25uSGNWbA%3D%3D
    // This code is needed by auth0Client.handleRedirectCallback() to get the Auth0 appState
    if (!window.location.search.includes('code=')) {
      throw new UnspecifiedError(
        'Cannot handle Auth0 redirect callback: missing queryParam code'
      );
    }

    return this.auth0Client.handleRedirectCallback();
  };
}
