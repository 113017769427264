import { useApolloLazyQuery } from '@core/hooks/useApolloQuery.hook';
import { FunctionComponentWithChildren } from '@core/types/functionComponent.types';
import { graphql } from 'graphql/generated';
import { PermissionName, User, UserLead } from 'graphql/generated/graphql';
import { useCheckIfUserIsAuthenticated } from 'modules/Authentication/hooks/useCheckIfUserIsAuthenticated.hook';
import { AuthRole } from 'modules/Authentication/hooks/useIsAuthorizedOrRedirectToHomePage.hook';
import { checkIsAdmin } from 'modules/Authentication/utils/userRole';
import {
  MyUser,
  MyUserContextProvider,
} from 'modules/MyUser/context/MyUserContext';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const myUserQuery = graphql(`
  query myUser {
    myUser {
      __typename
      ... on User {
        ...RecruiterLayout_User
        ...HiringManagerLayout_User
        firstName
        lastName
        email
        phoneNumber
        profilePictureUrl
        mainBusinessType
        termsOfServiceVersion
        permissions {
          name
        }
      }
      ... on UserLead {
        ...MyUserProvider_UserLead
      }
    }
  }
  fragment MyUserProvider_UserLead on UserLead {
    id
    email
    mainBusinessType
  }
`);

type MinimalMarketplaceUser = Required<Pick<User, '__typename'>>;
type MinimalUserLead = Required<Pick<UserLead, '__typename'>>;

/**
 * A generic version to determine if a user is a marketplace user or a user lead
 */
export function isMarketplaceUser<
  TUser extends MinimalMarketplaceUser,
  TUserLead extends MinimalUserLead
>(
  myUser: TUser | TUserLead | null | undefined
): myUser is Exclude<TUser, null | undefined> {
  return myUser?.__typename === 'User';
}

export function isUserLead<
  TUser extends MinimalMarketplaceUser,
  TUserLead extends MinimalUserLead
>(myUser: TUser | TUserLead | null | undefined): myUser is TUserLead {
  return myUser?.__typename === 'UserLead';
}

export const MyUserProvider: FunctionComponentWithChildren = ({ children }) => {
  const [authRoles, setAuthRoles] = useState<AuthRole[]>([]);

  const [queryMyUser, { data: myUserData, loading: isLoadingMyUser }] =
    useApolloLazyQuery(myUserQuery);

  const { checkIfUserIsAuthenticated } = useCheckIfUserIsAuthenticated();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkIfUserIsAuthenticated({
      onUserAuthenticated: async () => {
        await queryMyUser();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No dependencies! We fetch myUser if user is authenticated

  const getMyMarketplaceUser = useCallback(
    (myUser: MyUser | null | undefined) => {
      return isMarketplaceUser(myUser) ? myUser : null;
    },
    []
  );

  const getMyUserLead = useCallback((myUser: MyUser | null | undefined) => {
    return isUserLead(myUser) ? myUser : null;
  }, []);

  const myUser = useMemo(() => {
    return myUserData?.myUser ?? null;
  }, [myUserData?.myUser]);

  const myMarketplaceUser = useMemo(() => {
    return getMyMarketplaceUser(myUserData?.myUser);
  }, [getMyMarketplaceUser, myUserData?.myUser]);

  const myUserLead = useMemo(() => {
    return getMyUserLead(myUserData?.myUser);
  }, [getMyUserLead, myUserData?.myUser]);

  const isMyUserAdmin = useMemo(() => {
    return !!myMarketplaceUser && checkIsAdmin(myMarketplaceUser);
  }, [myMarketplaceUser]);

  const hasAgreedToLastTermsOfServiceVersion = useMemo(() => {
    return (
      myMarketplaceUser?.termsOfServiceVersion ===
      Number(process.env.NEXT_PUBLIC_LAST_TERMS_OF_SERVICE_VERSION)
    );
  }, [myMarketplaceUser]);

  const hasAgreedToMinimumRequiredTermsOfServiceVersion = useMemo(() => {
    return typeof myMarketplaceUser?.termsOfServiceVersion === 'number'
      ? myMarketplaceUser.termsOfServiceVersion >= 1
      : false;
  }, [myMarketplaceUser]);

  const hasPermission = useCallback(
    (permission: PermissionName): boolean => {
      return (
        myMarketplaceUser?.permissions.some(
          ({ name }) => name === permission
        ) ?? false
      );
    },
    [myMarketplaceUser]
  );

  const refetchMyUser = useCallback(async () => {
    const { data: myUserData } = await queryMyUser();

    return {
      myMarketplaceUser: getMyMarketplaceUser(myUserData?.myUser),
      myUserLead: getMyUserLead(myUserData?.myUser),
    };
  }, [getMyMarketplaceUser, getMyUserLead, queryMyUser]);

  return (
    <MyUserContextProvider
      value={{
        myUser,
        myMarketplaceUser,
        myUserLead,
        isMyUserAdmin,
        isLoadingMyUser,
        hasAgreedToLastTermsOfServiceVersion,
        hasAgreedToMinimumRequiredTermsOfServiceVersion,
        hasPermission,
        refetchMyUser,
        authRoles: { get: () => authRoles, set: setAuthRoles },
      }}
    >
      {children}
    </MyUserContextProvider>
  );
};
