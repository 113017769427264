import {
  AnalyticService,
  AnalyticsEvent,
  AnalyticsUser,
} from 'modules/Analytics/analytics.interface';

export class CompositeAnalyticService implements AnalyticService {
  constructor(private readonly analyticServices: AnalyticService[]) {
    if (typeof window !== 'undefined') {
      // Initialize the analytics service when 'window' is defined
      this.init();
    }
  }

  init = () => {
    this.analyticServices.forEach((service) => service.init());
  };

  trackGqlOperation = (operationName: string, payload?: object) => {
    this.analyticServices.forEach((service) =>
      service.trackGqlOperation(operationName, payload)
    );
  };

  trackEvent = <TAnalyticsEventName extends keyof AnalyticsEvent>(
    eventName: TAnalyticsEventName,
    payload: AnalyticsEvent[TAnalyticsEventName]
  ) => {
    this.analyticServices.forEach((service) =>
      service.trackEvent(eventName, payload)
    );
  };

  /**
   * Call underlying Analytic Services
   * @param maybePage if not provided, will default to window.location
   */
  trackPageView = (maybePage: string) => {
    const page =
      maybePage || `${window.location.pathname}${window.location.search}`;

    this.analyticServices.forEach((service) => service.trackPageView(page));
  };

  identifyUser = (user: AnalyticsUser) => {
    this.analyticServices.forEach((service) => service.identifyUser(user));
  };

  forgetUser = () => {
    this.analyticServices.forEach((service) => service.forgetUser());
  };
}
