import {
  AnalyticService,
  AnalyticsEvent,
  AnalyticsUser,
} from 'modules/Analytics/analytics.interface';
import { reportErrorToSentry } from 'modules/Analytics/utils/reportError.utils';
import posthog from 'posthog-js';
import { PostHog } from 'posthog-js/react';

export class PosthogAnalyticService implements AnalyticService {
  private readonly posthogClient: PostHog;

  constructor() {
    this.posthogClient = posthog;
  }

  init = () => {
    // Since PostHog is useful for more than the Analytics, it's already initiated in modules/PostHog/PostHogProvider.tsx
  };

  trackGqlOperation = (operationName: string, payload?: object) => {
    /**
     * Here, the first parameter is the event name.
     * The second are the associated properties.
     *
     * Filtering is more simple based on properties in posthog interface, that's why it's added to the payload
     */
    this.posthogClient.capture(operationName, {
      ...payload,
      eventName: operationName,
    });
  };

  trackEvent = <TAnalyticsEventName extends keyof AnalyticsEvent>(
    eventName: TAnalyticsEventName,
    payload: AnalyticsEvent[TAnalyticsEventName]
  ) => {
    /**
     * Here, the first parameter is the event name.
     * The second are the associated properties.
     *
     * Filtering is more simple based on properties in posthog interface, that's why it's added to the payload
     */
    this.posthogClient.capture(eventName, { ...payload, eventName });
  };

  trackPageView = (_page: string) => {
    // See posthog documentation: https://posthog.com/tutorials/nextjs-analytics#capturing-pageviews-in-nextjs
    this.posthogClient.capture('$pageview');
  };

  /**
   * We follow the posthog documentation: https://posthog.com/tutorials/nextjs-analytics#identifying-users
   */
  identifyUser = (user: AnalyticsUser) => {
    try {
      posthog.identify(user.email, {
        email: user.email,
        auth0Id: user.authUserId,
        mainBusinessType: user.mainBusinessType,
        firstName: user.firstName,
        lastName: user.lastName,
        internalId: user.internalId,
      });
    } catch (error) {
      reportErrorToSentry(error);
    }
  };

  forgetUser = () => {
    this.posthogClient.reset();
  };
}
