import {
  MissingEnvironmentVariableError,
  UnspecifiedError,
} from '@core/errors';
import * as BrowserSideSentry from '@sentry/browser';
import { BrowserOptions } from '@sentry/browser';
import { BrowserClientOptions } from '@sentry/browser/types/client';
import * as ServerSideSentry from '@sentry/node';
import { BrowserTracing } from '@sentry/tracing';
import {
  AnalyticService,
  AnalyticsEvent,
  AnalyticsUser,
} from 'modules/Analytics/analytics.interface';
import posthog from 'posthog-js';
import * as uuid from 'uuid';

export class SentryAnalyticService implements AnalyticService {
  init = () => {
    const IS_SENTRY_ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';

    const replaysSessionSampleRate = 0.1; // We have a quota of 10K replays per month, we want to optimize this number so that we record the maximum of sessions without exceeding our quota
    const replaysOnErrorSampleRate = 1.0; // We want to record all sessions with errors

    const ENV = process.env.NEXT_PUBLIC_ENV;
    if (!ENV) {
      throw new MissingEnvironmentVariableError({
        envVarName: 'NEXT_PUBLIC_ENV',
      });
    }
    const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
    if (!SENTRY_DSN) {
      throw new MissingEnvironmentVariableError({
        envVarName: 'NEXT_PUBLIC_SENTRY_DSN',
      });
    }
    const VERCEL_GIT_COMMIT_SHA =
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ??
      process.env.VERCEL_GIT_COMMIT_SHA;

    if (
      !VERCEL_GIT_COMMIT_SHA &&
      process.env.NEXT_PUBLIC_SENTRY_WEBPACK_PLUGIN_ENABLED === 'true'
    ) {
      throw new UnspecifiedError(
        'Cannot initialize Sentry client: either NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA OR VERCEL_GIT_COMMIT_SHA should be defined'
      );
    }

    const sentryConfig: BrowserOptions | ServerSideSentry.NodeOptions = {
      enabled: IS_SENTRY_ENABLED,
      dsn: SENTRY_DSN,
      // We use the same release name as in next.config.js file: https://github.com/muzzo-tech/job-marketplace-website/blob/5a989327ebe5fda496b8061f239990590c07c3bb/next.config.js#L40
      release: VERCEL_GIT_COMMIT_SHA,
      environment: ENV,
      normalizeDepth: 11,
    };

    // This configures the initialization of Sentry on the server and the app.
    // The config you add here will be used whenever the server handles a request.
    // https://docs.sentry.io/platforms/javascript/guides/nextjs/
    // TODO: use @sentry/nextjs instead of @sentry/node and @sentry/browser: https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/releases/
    const sessionId = uuid.v4();
    const SESSION_ID_TAG = 'session_id';

    if (typeof window === 'undefined') {
      ServerSideSentry.init(sentryConfig);
      // generate session id to find all errors during the user session
      ServerSideSentry.configureScope((scope) => {
        scope.setTag(SESSION_ID_TAG, sessionId);
      });
    } else {
      const integrations: BrowserClientOptions['integrations'] = [
        // Configuration taken from doc : https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/
        new BrowserTracing({
          tracingOrigins: [process.env.NEXT_PUBLIC_API_URL || ''],
        }),
      ];

      if (process.env.NEXT_PUBLIC_SENTRY_SESSION_REPLAY_ENABLED === 'true') {
        integrations.push(
          // See this doc to set up Sentry Session Replay
          // https://docs.sentry.io/platforms/javascript/guides/nextjs/session-replay/
          new BrowserSideSentry.Replay({
            maskAllInputs: false,
            maskAllText: false,
            blockAllMedia: false,
            sessionSampleRate: replaysSessionSampleRate,
            errorSampleRate: replaysOnErrorSampleRate,
          })
        );
      }
      const sentryProjectId = new URL(SENTRY_DSN).pathname.split('/')[1];
      if (process.env.NEXT_PUBLIC_POSTHOG_KEY && sentryProjectId) {
        integrations.push(
          new posthog.SentryIntegration(
            posthog,
            process.env.SENTRY_ORG,
            parseInt(sentryProjectId, 10)
          )
        );
      }

      BrowserSideSentry.init({
        ...sentryConfig,
        integrations,
        replaysSessionSampleRate,
        replaysOnErrorSampleRate,
      });

      // generate session id to find all errors during the user session
      BrowserSideSentry.configureScope((scope) => {
        scope.setTag(SESSION_ID_TAG, sessionId);
      });
    }
  };

  // TODO: implement the trackGqlOperation logic if useful
  trackGqlOperation = (_operationName: string, _payload?: object) => {};

  // TODO: implement the track event logic if useful
  trackEvent = <TAnalyticsEventName extends keyof AnalyticsEvent>(
    _eventName: TAnalyticsEventName,
    _payload: AnalyticsEvent[TAnalyticsEventName]
  ) => {};

  // TODO: implement the track event logic if useful
  trackPageView = (_page: string) => {};

  identifyUser = (user: AnalyticsUser) => {
    this.setSentryUser(user);
  };

  forgetUser = () => {
    this.setSentryUser(null);
  };

  setSentryUser = (user: AnalyticsUser | null) => {
    if (typeof window === 'undefined') {
      ServerSideSentry.setUser(user);
      ServerSideSentry.configureScope((scope) => {
        scope.setTag('user_email', user?.email ?? null);
      });
    } else {
      BrowserSideSentry.setUser(user);
      BrowserSideSentry.configureScope((scope) => {
        scope.setTag('user_email', user?.email ?? null);
      });
    }
  };
}
