import { onError } from '@apollo/client/link/error';
import * as BrowserSideSentry from '@sentry/browser';
import { reportErrorToSentry } from 'modules/Analytics/utils/reportError.utils';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  // GraphQLErrors ? When operation is not supported by the server
  // see: https://www.apollographql.com/docs/react/data/error-handling/#graphql-errors
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      BrowserSideSentry.configureScope((scope) => {
        // As error are monitored on the API Side, we don't need to report them as error to Sentry
        // We still want to report those errors to Sentry in order to watch the sentry session replay
        scope.setLevel('info');
        scope.setExtra('error', {
          name: error.name,
          message: error.message,
          stack: error.stack,
          extensions: error.extensions,
          path: error.path,
          locations: error.locations,
          originalError: error.originalError,
          nodes: error.nodes,
          positions: error.positions,
          source: error.source,
        });

        const customError = new Error();
        customError.name = `[GraphQL error]`;
        customError.message = error.message;
        customError.stack = error.stack;

        reportErrorToSentry(customError);
      });
    });
  }

  // Network errors ? When server returns status code > 400
  // see: https://www.apollographql.com/docs/react/data/error-handling/#network-errors
  if (networkError) {
    BrowserSideSentry.configureScope((scope) => {
      // As error are monitored on the API Side, we don't need to report them as error to Sentry
      scope.setExtra('error', {
        ...networkError,
      });
      reportErrorToSentry(networkError);
    });
  }
});
