import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { getAppBaseUrl } from '@core/config/environmentVariables/getAppBaseUrl';
import { MissingEnvironmentVariableError } from '@core/errors';
import { AnalyticService } from 'modules/Analytics/analytics.interface';
import { AuthenticationAuth0Service } from 'modules/Authentication/authentication.auth0.service';
import { AuthService } from 'modules/Authentication/authentication.interface';
import { AuthenticationOfflineService } from 'modules/Authentication/authentication.offline.service';
import { Routes } from 'router/routes';

const getAuth0ClientOptions = (): Auth0ClientOptions => {
  const domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
  const clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
  if (!domain) {
    throw new MissingEnvironmentVariableError({
      envVarName: 'NEXT_PUBLIC_AUTH0_DOMAIN',
    });
  }
  if (!clientId) {
    throw new MissingEnvironmentVariableError({
      envVarName: 'NEXT_PUBLIC_AUTH0_CLIENT_ID',
    });
  }

  return {
    domain,
    client_id: clientId,
    redirect_uri: `${getAppBaseUrl()}${Routes.REDIRECTION_AFTER_LOGIN}`,
    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    scope: 'openid profile email offline_access',
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  };
};
export const getAuthService = ({
  analyticService,
}: {
  analyticService: AnalyticService;
}): AuthService => {
  if (process.env.USE_MOCK_AUTH === 'true') {
    return new AuthenticationOfflineService();
  }

  const auth0ClientOptions = getAuth0ClientOptions();
  const auth0Client = new Auth0Client(auth0ClientOptions);

  return new AuthenticationAuth0Service(auth0Client, analyticService);
};
