import { FieldMergeFunction, FieldReadFunction } from '@apollo/client';
import { mergeIncomingHasNextPageIntoCache } from '@core/clients/apollo/cache/mergeAndReadMethods/mergeIncomingHasNextPageIntoCache';
import {
  ExistingAnonymizedProfileCache,
  IncomingForAnonymizedProfileCache,
  PartialAnonymizedProfileOffsetBasedConnection,
  PartialAnonymizedProfileOffsetBasedConnectionByJobOfferId,
} from '@core/clients/apollo/cache/mergeAndReadMethods/paginatedJobOfferSuggestedAnonymizedProfiles.cache.types';
import { UndefinedGraphQLFieldError } from '@core/errors';
import {
  QueryPaginatedJobOfferSuggestedAnonymizedProfilesArgs,
  UseEmployerJobOfferPaginatedSuggestedAnonymizedProfiles_PaginatedJobOfferSuggestedAnonymizedProfilesQueryQueryVariables,
} from 'graphql/generated/graphql';
import { uniqBy } from 'lodash';

export const mergeInconmingPaginatedJobOfferSuggestedAnonymizedProfiles: FieldMergeFunction<
  ExistingAnonymizedProfileCache,
  IncomingForAnonymizedProfileCache
> = (
  existing,
  incoming,
  options
): PartialAnonymizedProfileOffsetBasedConnectionByJobOfferId => {
  const args =
    options.args as QueryPaginatedJobOfferSuggestedAnonymizedProfilesArgs | null;
  const { jobOfferId, offsetBasedPaginationParams } = args?.input ?? {};
  const { offset } = offsetBasedPaginationParams ?? {};

  if (offset === undefined) {
    throw new UndefinedGraphQLFieldError({
      fieldName: 'offset',
      operationName: 'paginatedJobOfferSuggestedAnonymizedProfiles',
    });
  }
  if (jobOfferId === undefined) {
    throw new UndefinedGraphQLFieldError({
      fieldName: 'jobOfferId',
      operationName: 'paginatedJobOfferSuggestedAnonymizedProfiles',
    });
  }

  const existingNodes = existing?.[jobOfferId]?.nodes ?? [];
  const incomingNodes = incoming.nodes;

  const mergedNodes = uniqBy(
    [...existingNodes, ...incomingNodes],
    (anonymizedProfileRef) => anonymizedProfileRef.__ref
  );

  return {
    ...(existing ?? {}),
    [jobOfferId]: {
      __typename: incoming.__typename,
      hasNextPage: mergeIncomingHasNextPageIntoCache({
        existingHasNextPage:
          existing && existing[jobOfferId] && existing[jobOfferId]?.hasNextPage,
        incomingHasNextPage: incoming.hasNextPage,
      }),
      nodes: mergedNodes,
      totalCount: incoming.totalCount,
    },
  };
};

export const readPaginatedJobOfferSuggestedAnonymizedProfilesFromCache: FieldReadFunction<
  ExistingAnonymizedProfileCache,
  PartialAnonymizedProfileOffsetBasedConnection
> = (existing, options) => {
  const args =
    options.args as UseEmployerJobOfferPaginatedSuggestedAnonymizedProfiles_PaginatedJobOfferSuggestedAnonymizedProfilesQueryQueryVariables | null; // Hack because typing of FieldFunctionOptions is tough

  const jobOfferId = args?.input.jobOfferId;

  if (!jobOfferId) {
    throw new UndefinedGraphQLFieldError({
      fieldName: 'jobOfferId',
      operationName: 'paginatedJobOfferSuggestedAnonymizedProfiles',
    });
  }
  // A read function should always return undefined if existing is
  // undefined. Returning undefined signals that the field is
  // missing from the cache, which instructs Apollo Client to
  // fetch its value from your GraphQL server.
  if (!existing || !existing[jobOfferId]) {
    return undefined;
  }
  return existing[jobOfferId];
};
